import AddCoffeeForm from "./AddCoffeeForm";
import CoffeeTable from "./CoffeeTable";
import OrderTable from "./OrderTable";

const AdminDashboard = () => {
  return (
    <div className="grid gap-6">
      <h1>Admin Dashboard</h1>
      <CoffeeTable />
      <AddCoffeeForm />
      <OrderTable />
    </div>
  );
};
export default AdminDashboard;
