import { Button } from "@/components/ui/button";
import { useAuthContext } from "@/hooks/useAuthContext";
import { useCollection } from "@/hooks/useCollection";
import { Link } from "react-router-dom";
import type { greenCoffee } from "./AdminDashboard/AddCoffeeForm";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ExternalLink, MinusCircle, Plus, PlusCircle } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import useCart, { useCartContext } from "@/hooks/useCartContext";
import CoffeeCard from "@/components/CoffeeCard";

const links = [
  { name: "Login", path: "/login" },
  { name: "Signup", path: "/signup" },
  { name: "Dashboard", path: "/dashboard" },
];

const Home = () => {
  const { isAdmin, user } = useAuthContext();
  const { coffees }: { coffees: greenCoffee[] } = useCartContext();

  return (
    <div className="grid gap-4 sm:gap-8">
      {user && (
        <h1 className="text-2xl sm:text-4xl font-bold">
          Welcome, <span className="text-secondary">{user.displayName}</span>
        </h1>
      )}
      <section
        className="grid gap-4 sm:gap-6 lg:gap-8 sm:grid-cols-2 lg:grid-cols-3 items-start"
        aria-label="Available Coffees"
      >
        {coffees &&
          coffees
            .filter((c) => c.status === "active")
            .map((coffee, index) => (
              <CoffeeCard key={coffee.id} coffee={coffee} index={index} />
            ))}
      </section>
    </div>
  );
};
export default Home;
