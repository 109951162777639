import { Toaster } from "@/components/ui/toaster";
import { Outlet } from "react-router-dom";
import Nav from "./Nav";
import { useAuthContext } from "@/hooks/useAuthContext";

const Layout = () => {
  const { authIsReady } = useAuthContext();
  return (
    <div className="grid grid-rows-layout gap-6 sm:gap-10 min-h-screen text-lg sm:text-xl">
      <Nav />
      <main className="container">
        {authIsReady && <Outlet />}
        <Toaster />
      </main>
      <footer className="text-center p-2 border-t border-secondary text-sm">
        Pennington Roasting
      </footer>
    </div>
  );
};
export default Layout;
