import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { useAuthContext } from "@/hooks/useAuthContext";
import { useLogout } from "@/hooks/useLogout";
import logomark from "@/assets/icon-horizontal.png";
import {
  Loader2,
  LogIn,
  LogOut,
  ShoppingBag,
  ShoppingCart,
  UserCircle2,
} from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { useCartContext } from "@/hooks/useCartContext";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import CartTable from "../Checkout/NavCart";

const Nav = () => {
  const { user, authIsReady } = useAuthContext();
  const { cart } = useCartContext();
  const navigate = useNavigate();

  const handleCheckoutClick = () => {
    if (!user) {
      toast({
        title: "Please login",
        description: "You must be logged in to place an order",
      });
      return navigate("/login");
    }
    navigate("/checkout");
  };

  return (
    <nav className="flex justify-between items-center gap-6 container py-2">
      <Button
        variant="link"
        size={"minimized"}
        asChild
        className="w-32 sm:w-40 lg:w-60 h-auto"
      >
        <Link to="/" aria-label="Go Home">
          <img src={logomark} alt="" width="200" className="w-full" />
        </Link>
      </Button>
      <ul className="flex items-center sm:space-x-4">
        {!user && authIsReady && (
          <li>
            <Button
              variant="secondary"
              asChild
              size="sm"
              className="hidden sm:flex"
            >
              <Link
                to="/login"
                aria-label="login"
                className="flex items-center gap-1"
              >
                <span>Login</span>
                <LogIn className="h-4 w-4" />
              </Link>
            </Button>
          </li>
        )}
        {user && authIsReady && (
          <li>
            <Button
              variant="ghost"
              asChild
              size="icon"
              className="items-center gap-1"
            >
              <Link to="/dashboard" aria-label="Account dashboard">
                <UserCircle2 className="h-6 w-6" />
              </Link>
            </Button>
          </li>
        )}
        <li>
          <Popover>
            <PopoverTrigger className="relative" asChild>
              <Button variant="ghost" size="icon">
                <div>
                  <i className="absolute empty:w-0 empty:h-0 text-xs text-white h-5 w-5 bg-secondary rounded-full grid place-items-center -top-1 -right-1">
                    {cart.length === 0 ? "" : cart.length}
                  </i>
                  <ShoppingBag width={44} />
                </div>
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto max-w-fit grid gap-2">
              {cart && cart.length === 0 && <p>No items in cart</p>}
              {cart && cart.length > 0 && (
                <>
                  <CartTable />
                  <PopoverClose>
                    <Button
                      size="sm"
                      variant={"secondary"}
                      className="w-full"
                      onClick={handleCheckoutClick}
                    >
                      Checkout
                    </Button>
                  </PopoverClose>
                </>
              )}
            </PopoverContent>
          </Popover>
        </li>
      </ul>
    </nav>
  );
};
export default Nav;
