import { useState, useEffect } from "react";
import { useAuthContext } from "./useAuthContext";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { projectAuth, projectFirestore, timestamp } from "../firebase/config";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { CartOrder } from "@/context/CartContext";

export type UserData = {
  displayName: string;
  email: string;
  lastOnline: Timestamp;
  dateCreated: Timestamp;
  orders: CartOrder[] | [];
  favoritedBeans: [];
  role: "customer" | "admin";
};

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const signup = async ({
    email,
    password,
    displayName,
  }: {
    email: string;
    password: string;
    displayName: string;
  }) => {
    setError(null);
    setIsPending(true);

    try {
      // signup
      const res = await createUserWithEmailAndPassword(
        projectAuth,
        email,
        password
      );

      if (!res) {
        throw new Error("Could not complete signup");
      }

      // add display name to user
      await updateProfile(res.user, { displayName });

      // create a user doc
      await setDoc(doc(projectFirestore, "users", res.user.uid), {
        displayName,
        email: res.user.email,
        lastOnline: timestamp.fromDate(new Date()),
        dateCreated: timestamp.fromDate(new Date()),
        orders: [],
        favoritedBeans: [],
        role: "customer",
      });

      // dispatch login action
      dispatch({ type: "LOGIN", payload: res.user });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  // useEffect(() => {
  //   return () => setIsCancelled(true);
  // }, []);

  return { signup, error, isPending };
};
