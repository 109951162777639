import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Switch } from "@/components/ui/switch";
import { ToastAction } from "@/components/ui/toast";
import { toast } from "@/components/ui/use-toast";
import { useCollection } from "@/hooks/useCollection";
import { useFirestore } from "@/hooks/useFirestore";
import { Trash2, View } from "lucide-react";
import { Link } from "react-router-dom";
import { greenCoffee } from "./AddCoffeeForm";
import getCoffeeDetails from "@/lib/getCoffeeDetails";
import formatCentsToCurrency from "@/lib/formatCentsToCurrency";
import { UserData } from "@/hooks/useSignup";

export default function TableDemo() {
  const { documents: coffees }: { documents: greenCoffee[] } =
    useCollection("coffees");
  const { documents: users }: { documents: UserData[] } =
    useCollection("users");
  const { deleteDocument, updateDocument, response } = useFirestore("coffees");

  const getNumberOfCoffeeOrders = (id: string) => {
    if (!users) return;
    const totalNumberOrdered = users.reduce((acc, user) => {
      const numberForUser = user.orders
        .map((order) => order.itemsPurchased)
        .flat()
        .filter((item) => item === id).length;

      return acc + numberForUser;
    }, 0);
    return totalNumberOrdered.toString();
  };

  const handleStatusClick = async (doc: greenCoffee) => {
    await updateDocument(doc.id, {
      status: `${doc.status === "active" ? "inactive" : "active"}`,
    });
    const coffeeName = getCoffeeDetails({
      coffeeID: doc.id,
      coffees: coffees,
      qty: doc.current_inventory,
      keys: ["name"],
    });
    toast({
      title: "Status updated",
      description: `${coffeeName.name} is now set to ${
        doc.status === "active" ? "inactive" : "active"
      }`,
    });
  };
  const handleComingSoonClick = async (doc: greenCoffee) => {
    await updateDocument(doc.id, {
      coming_soon: doc.coming_soon === true ? false : true,
    });
    const coffeeName = getCoffeeDetails({
      coffeeID: doc.id,
      coffees: coffees,
      qty: doc.current_inventory,
      keys: ["name"],
    });
    toast({
      title: "Banner updated",
      description: `${coffeeName.name} is now set to ${
        doc.coming_soon === false
          ? "show “Coming Soon”"
          : "not show a “Coming Soon” banner"
      }.`,
    });
  };

  const handleDeleteCoffee = async (id: string) => {
    try {
      await deleteDocument(id);
    } catch (error) {
      console.log(error);
    }
    // if (!response.success) {
    //   toast({
    //     title: "We have a problem!",
    //     description: "We couldn’t delete the coffee. Please try again.",
    //     action: (
    //       <ToastAction
    //         altText="Try again"
    //         onClick={() => handleDeleteCoffee(id)}
    //       >
    //         Try again
    //       </ToastAction>
    //     ),
    //   });
    // } else {
    return toast({
      title: "Coffee deleted",
      description: "Coffee successfully deleted",
    });
    // }
    // console.log(response);
    // return toast({
    //   title: "Bye bye!",
    //   description: "Coffee successfully deleted",
    // });
  };

  return (
    <Table>
      <TableCaption>A list of your recent coffees.</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead>Active</TableHead>
          <TableHead>Coming Soon</TableHead>
          <TableHead>Origin</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Process</TableHead>
          <TableHead>Inventory</TableHead>
          <TableHead>Sold</TableHead>
          <TableHead>Customer Cost</TableHead>
          <TableHead>Profit</TableHead>
          <TableHead></TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {coffees &&
          coffees.map((coffee: greenCoffee) => (
            <TableRow key={coffee.id}>
              <TableCell>
                <Switch
                  className="data-[state=checked]:bg-emerald-500"
                  onClick={() => handleStatusClick(coffee)}
                  checked={coffee.status === "active" ? true : false}
                />
              </TableCell>
              <TableCell>
                <Switch
                  className="data-[state=checked]:bg-emerald-500"
                  onClick={() => handleComingSoonClick(coffee)}
                  checked={coffee.coming_soon === true ? true : false}
                />
              </TableCell>
              <TableCell className="font-medium">{coffee.origin}</TableCell>
              <TableCell>{coffee.name}</TableCell>
              <TableCell>{coffee.process}</TableCell>
              <TableCell>{coffee.current_inventory}</TableCell>
              <TableCell>
                {users ? getNumberOfCoffeeOrders(coffee.id) : ""}
              </TableCell>
              <TableCell>
                {formatCentsToCurrency(coffee.customer_price_in_cents)}
              </TableCell>
              <TableCell>
                {users
                  ? formatCentsToCurrency(
                      getNumberOfCoffeeOrders(coffee.id) *
                        coffee.customer_price_in_cents -
                        coffee.my_cost_for_inventory_in_cents
                    )
                  : ""}
              </TableCell>
              <TableCell>
                <Button
                  variant="destructive"
                  size="icon"
                  aria-label={`Delete ${coffee.name} Bean`}
                  onClick={() => handleDeleteCoffee(coffee.id)}
                >
                  <Trash2 width="16" />
                </Button>
              </TableCell>
              <TableCell>
                {/* edit after seeing bean */}
                <Button
                  size="icon"
                  aria-label={`View ${coffee.name} Bean`}
                  asChild
                >
                  <Link to={`/bean/${coffee.id}`}>
                    <View width="16" />
                  </Link>
                </Button>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
