import { greenCoffee } from "@/pages/AdminDashboard/AddCoffeeForm";

export type CoffeeDetails = {
  [key in keyof greenCoffee]: greenCoffee[key];
};

export default function getCoffeeDetails({
  coffeeID,
  coffees,
  keys,
  qty,
}: {
  qty: number;
  coffeeID: string;
  coffees: greenCoffee[];
  keys: Array<keyof greenCoffee>;
}) {
  const coffee = coffees.find((coffee) => coffee.id === coffeeID);
  if (!coffee) {
    throw new Error(`No coffee found for id: ${coffeeID}`);
  }

  const coffeeDetails = keys.reduce((acc, curr) => {
    acc[curr] = coffee[curr];
    return acc;
  }, {} as CoffeeDetails);

  return { ...coffeeDetails, qty, id: coffeeID };
}
