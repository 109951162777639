import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { Timestamp, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDHNgHiD-giaMFFoTZ-0yxa7LFsDZ6jttE",
  authDomain: "fir-coffee-70473.firebaseapp.com",
  projectId: "fir-coffee-70473",
  storageBucket: "fir-coffee-70473.appspot.com",
  messagingSenderId: "524226440789",
  appId: "1:524226440789:web:04efb03582f7f5b4d72edc",
};

// Initialize firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const projectAuth = getAuth(app);
const projectFirestore = getFirestore(app);
const projectStorage = getStorage(app);
const projectFunctions = getFunctions(app);
const timestamp = Timestamp;

export {
  projectAuth,
  projectFirestore,
  projectStorage,
  projectFunctions,
  timestamp,
};
