import { useState, useEffect } from "react";
import { projectAuth, projectFirestore, timestamp } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export const useLogin = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setError(null);
    setIsPending(true);

    try {
      // login
      const res = await signInWithEmailAndPassword(
        projectAuth,
        email,
        password
      );

      // get admin status on login
      const docRef = doc(projectFirestore, "users", res.user.uid);
      const docSnap = await getDoc(docRef);
      const adminStatus = docSnap.data().role === "admin";

      // update last online status
      await updateDoc(docRef, {
        lastOnline: timestamp.fromDate(new Date()),
      });

      // dispatch login action
      dispatch({ type: "LOGIN", payload: res.user, isAdmin: adminStatus });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
      setError(err.message);
      setIsPending(false);
      console.log(err.message);
      // return rejected promise
      return Promise.reject(err.message);
    }
  };

  // useEffect(() => {
  //   return () => setIsCancelled(true);
  // }, []);

  return { login, isPending, error };
};
