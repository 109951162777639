import { projectFirestore, timestamp } from "@/firebase/config";
import { useAuthContext } from "@/hooks/useAuthContext";
import { useCollection } from "@/hooks/useCollection";
import { greenCoffee } from "@/pages/AdminDashboard/AddCoffeeForm";
import { Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import { createContext, useState } from "react";

export const CartContext = createContext();

export type CartOrder = {
  createdAt: Timestamp;
  itemsPurchased: string[];
  orderTotal: number;
  paid: boolean;
};

export const CartContextProvider = ({ children }) => {
  const { documents: coffees }: { documents: greenCoffee[] } =
    useCollection("coffees");
  const { user } = useAuthContext();
  const [cart, setCart] = useState([]);

  const checkout = async (
    orderItems: [string, number][],
    orderTotal: number
  ) => {
    orderItems.forEach(async ([id, qty]) => {
      const currentCoffee = coffees.find((c) => c.id === id);
      if (!currentCoffee) throw new Error("Coffee not found");
      if (currentCoffee.current_inventory === 0) {
        throw new Error("Out of stock");
      }
      await updateDoc(doc(projectFirestore, "coffees", id), {
        current_inventory: currentCoffee.current_inventory - qty,
      });
    });

    const currentUserData = await getDoc(
      doc(projectFirestore, "users", user.uid)
    );

    await updateDoc(doc(projectFirestore, "users", user.uid), {
      orders: [
        ...currentUserData.data().orders,
        {
          createdAt: timestamp.fromDate(new Date()),
          itemsPurchased: cart,
          orderTotal,
          paid: true,
          fulfilled: false,
        },
      ],
    });

    return setCart([]);
  };

  const addToCart = async (productId) => {
    // const currentCoffee = coffees.find((c) => c.id === productId);
    // if (!currentCoffee) throw new Error("Coffee not found");
    // if (currentCoffee.current_inventory === 0) {
    //   throw new Error("Out of stock");
    // }
    // await updateDoc(doc(projectFirestore, "coffees", productId), {
    //   current_inventory: currentCoffee.current_inventory - 1,
    // });
    const newCart = [...cart, productId];
    setCart(newCart);
  };

  const removeFromCart = async (productId) => {
    const removeIndex = cart.findIndex((id) => id === productId);
    const newCart = [
      ...cart.slice(0, removeIndex),
      ...cart.slice(removeIndex + 1),
    ];
    setCart(newCart);
  };

  return (
    <CartContext.Provider
      value={{ cart, coffees, addToCart, removeFromCart, checkout }}
    >
      {children}
    </CartContext.Provider>
  );
};
