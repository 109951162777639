import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { projectFirestore } from "@/firebase/config";
import { useAuthContext } from "@/hooks/useAuthContext";
import formatCentsToCurrency from "@/lib/formatCentsToCurrency";
import formatTextList from "@/lib/formatTextList";
import formatTimestampToShortDate from "@/lib/formatTimestampToShortDate";
import { Timestamp, doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

const UserDashboard = () => {
  const { user } = useAuthContext();
  const [orders, setOrders] = useState<ProcessedOrder[] | []>([]);

  type RawOrder = {
    createdAt: Timestamp;
    itemsPurchased: string[];
    paid: boolean;
    orderTotal: number;
  };

  type ProcessedOrder = {
    id: number;
    date: string;
    status: "Paid" | "Unpaid";
    beans: string[];
    orderTotal: number;
  };

  useEffect(() => {
    const fetchOrders = async () => {
      const response = await getDoc(doc(projectFirestore, "users", user.uid));
      const userOrders = response.data().orders as RawOrder[];

      const processedOrders = await Promise.all(
        userOrders.map(async (order) => {
          return {
            id: order.createdAt.toMillis(),
            date: formatTimestampToShortDate(order.createdAt),
            status: order.paid ? "Paid" : "Unpaid",
            beans: await Promise.all(
              order.itemsPurchased.map(async (item) => {
                const orderDoc = await getDoc(
                  doc(projectFirestore, "coffees", item)
                );
                const beanName = await orderDoc.get("name");
                return beanName;
              })
            ),
            orderTotal: order.orderTotal,
          };
        })
      );

      setOrders(processedOrders);
    };
    fetchOrders();
  }, [user]);

  return (
    <div>
      {orders.length > 0 && (
        <Table className="max-w-fit">
          <TableCaption>A list of your recent invoices.</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">Date</TableHead>
              <TableHead className="w-[100px]">Status</TableHead>
              <TableHead>Order Items</TableHead>
              <TableHead className="text-right">Amount</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell>{order.date}</TableCell>
                <TableCell className="w-[100px]">{order.status}</TableCell>
                <TableCell>{formatTextList.format(order.beans)}</TableCell>
                <TableCell className="text-right">
                  {formatCentsToCurrency(order.orderTotal)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};
export default UserDashboard;
