import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

// component imports
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useSignup } from "@/hooks/useSignup";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";
import { Link } from "react-router-dom";

const formSchema = z.object({
  email: z.string().email({
    message: "Please enter a valid email address.",
  }),
  username: z
    .string()
    .min(2, {
      message: "Username must be at least 2 characters long.",
    })
    .max(20, {
      message: "Username may not be longer than 20 characters.",
    }),
  password: z
    .string()
    .min(8, {
      message: "Password must be at least 8 characters long.",
    })
    .max(30, {
      message: "Password may not be longer than 30 characters.",
    }),
  child: z.enum(["ella", "nora", "jude", "", "Ella", "Nora", "Jude"], {
    errorMap: () => ({
      message: "Please enter the name of one of my children.",
    }),
  }),
});

export default function Signup() {
  const { signup, error, isPending } = useSignup();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      username: "",
      password: "",
      child: "",
    },
  });

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    return signup({
      email: values.email,
      password: values.password,
      displayName: values.username,
    });
    // fix by returning a promise eventually
  }

  return (
    <div className="grid place-items-center min-h-full p-4 sm:p-8">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-4 border p-4 sm:p-8 rounded shadow-md"
        >
          <div className="grid gap-1">
            <h1 className="text-2xl font-bold">Create an account</h1>
            <FormDescription>
              Create an account or{" "}
              <Link to="/login" className="underline">
                login to your existing account
              </Link>
            </FormDescription>
          </div>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    placeholder="e.g., example@email.com"
                    {...field}
                    autoFocus
                    type="email"
                    required
                  />
                </FormControl>
                <FormDescription>
                  This is your email address used for signup.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Username</FormLabel>
                <FormControl>
                  <Input
                    placeholder="e.g., John"
                    {...field}
                    type="username"
                    required
                  />
                </FormControl>
                <FormDescription>
                  This is your public display name.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input {...field} type="password" required />
                </FormControl>
                <FormDescription>
                  Password must be at least 8 characters long.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="child"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Confirm you know me</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="text"
                    required
                    placeholder="e.g., Arabica"
                  />
                </FormControl>
                <FormDescription className="max-w-xs">
                  I only sell to people I know. Please provide the name of one
                  of my children.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          {!isPending && <Button type="submit">Create Account</Button>}
          {isPending && (
            <Button type="submit" disabled>
              Submitting…
            </Button>
          )}
          {error && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>
                Uh oh! There was a problem creating your account.
              </AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
        </form>
      </Form>
    </div>
  );
}
