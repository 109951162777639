import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

export type greenCoffee = {
  id: string;
  name: string;
  image_src: string;
  coming_soon: boolean;
  origin: string;
  process: string;
  tasting_notes: string;
  my_cost_for_inventory_in_cents: number;
  customer_price_in_cents: number;
  total_inventory: number;
  current_inventory: number;
  link_to_bean: string;
  status: "inactive" | "active";
  orders: string[];
  favorited_by_users: string[];
  reviews: string[];
};

// component imports
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Link } from "react-router-dom";
import { useLogin } from "@/hooks/useLogin";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";
import { useFirestore } from "@/hooks/useFirestore";
import { toast } from "@/components/ui/use-toast";
import { add, set } from "date-fns";
import { Textarea } from "@/components/ui/textarea";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";
import { ToastAction } from "@/components/ui/toast";
import { Checkbox } from "@/components/ui/checkbox";

const formSchema = z.object({
  name: z.string().max(35, {
    message: "Cannot be longer than 35 character",
  }),
  origin: z.string().max(25, {
    message: "Cannot be longer than 25 character",
  }),
  process: z.string().max(25, {
    message: "Cannot be longer than 25 character",
  }),
  tasting_notes: z.string().max(450, {
    message: "Cannot be longer than 450 character",
  }),
  coming_soon: z.boolean().default(false).optional(),
  my_cost_for_inventory_in_cents: z.coerce
    .number()
    .lte(29000, "Cannot be more than $290"),
  customer_price_in_cents: z.coerce
    .number()
    .lte(2000, "Cannot be more than $20")
    .gte(900, "Cannot be less than $9"),
  total_inventory: z.coerce.number(),
  current_inventory: z.coerce.number(),
  link_to_bean: z.string().url({
    message: "Please enter a valid URL",
  }),
  image_src: z.string().url({
    message: "Please enter a valid URL",
  }),
  status: z.enum(["active", "inactive"]),
  orders: z.array(z.string()),
  favorited_by_users: z.array(z.string()),
  reviews: z.array(z.string()),
});

export default function AddCoffeeForm() {
  const [open, setOpen] = useState(false);
  const { addDocument, response } = useFirestore("coffees");
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      origin: "",
      process: "",
      tasting_notes: "",
      my_cost_for_inventory_in_cents: 0,
      customer_price_in_cents: 0,
      total_inventory: 0,
      current_inventory: 0,
      link_to_bean: "",
      coming_soon: true,
      image_src: "",
      status: "inactive",
      orders: [],
      favorited_by_users: [],
      reviews: [],
    },
  });

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    setOpen(false);
    const docRef = await addDocument({
      name: values.name,
      origin: values.origin,
      process: values.process,
      tasting_notes: values.tasting_notes,
      my_cost_for_inventory_in_cents: Number(
        values.my_cost_for_inventory_in_cents
      ),
      image_src: values.image_src,
      coming_soon: values.coming_soon,
      customer_price_in_cents: Number(values.customer_price_in_cents),
      total_inventory: Number(values.total_inventory),
      current_inventory: Number(values.total_inventory),
      link_to_bean: values.link_to_bean,
      status: "inactive",
      orders: [],
      favorited_by_users: [],
      reviews: [],
    });
    // console.log(response);
    if (response.error) {
      return toast({
        title: "Error",
        description: "There was an error adding your coffee. Please try again",
        action: (
          <ToastAction altText="Try again" onClick={() => setOpen(true)}>
            Try again
          </ToastAction>
        ),
      });
    }
    form.reset();
    return toast({
      title: "Success!",
      description: "Your coffee has been added to the database.",
    });
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Add Bean</Button>
      </DialogTrigger>
      <DialogContent className="p-4 sm:p-8 md:min-w-[800px] overflow-y-scroll max-h-screen">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-6 w-full"
          >
            <DialogHeader>
              <DialogTitle>Add New Bean</DialogTitle>
              <DialogDescription>Add New Bean</DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 sm:grid-cols-2">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Bean Name</FormLabel>
                    <FormControl>
                      <Input {...field} type="text" required />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="origin"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Bean Origin</FormLabel>
                    <FormControl>
                      <Input {...field} type="text" required />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="process"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Process Type</FormLabel>
                    <FormControl>
                      <Input {...field} type="text" required />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="coming_soon"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4 self-start">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>Coming Soon Banner</FormLabel>
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="tasting_notes"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tasting Notes</FormLabel>
                    <FormControl>
                      <Textarea {...field} required />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="my_cost_for_inventory_in_cents"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Total Inventory Price</FormLabel>
                    <FormDescription>
                      My total cost for this bean in cents.
                    </FormDescription>
                    <FormControl>
                      <Input type="number" {...field} required />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="customer_price_in_cents"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Customer Price</FormLabel>
                    <FormDescription>
                      Provide sale price in cents.
                    </FormDescription>
                    <FormControl>
                      <Input
                        type="number"
                        min={900}
                        max={2000}
                        {...field}
                        required
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="total_inventory"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Total 12oz bags available</FormLabel>
                    <FormDescription>
                      Original amount of bags to sell
                    </FormDescription>
                    <FormControl>
                      <Input
                        type="number"
                        min={1}
                        max={28}
                        {...field}
                        required
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="link_to_bean"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Link to Bean online</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} required />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="image_src"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Image Url</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} required />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <Button type="submit">Add Bean</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
