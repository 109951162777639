// export function loader() {
//   const pizza = "yo";
//   return { pizza };
// }

import { useAuthContext } from "@/hooks/useAuthContext";
import UserDashboard from "../UserDashboard/UserDashboard";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { Button } from "@/components/ui/button";
import { useLogout } from "@/hooks/useLogout";
import { toast } from "@/components/ui/use-toast";
import { Loader2, LogOut } from "lucide-react";

// export function action({ request }) {
//   const data = await request.formData();
//   const { _action, ...values } = Object.fromEntries(data);
//   // new user submission
//   if (_action === "addCoffee") {
//     try {
//       localStorage.setItem("userName", JSON.stringify(values.userName));
//       return toast.success(`Welcome, ${values.userName}`);
//     } catch (e) {
//       throw new Error("There was a problem creating your account.");
//     }
//   }
//   return;
// }

const Dashboard = () => {
  const { user, isAdmin } = useAuthContext();
  const { logout, isPending } = useLogout();

  async function handleLogout() {
    await logout();
    toast({
      title: "Logged out",
      description: "You have been logged out",
    });
  }
  return (
    <div className="grid gap-6">
      <h1 className="text-2xl font-bold">
        Welcome back,{" "}
        <span className="text-secondary">{user && user.displayName}</span>
      </h1>
      {user && !isPending && (
        <Button
          onClick={handleLogout}
          className="flex items-center gap-1 max-w-fit"
        >
          <span>Logout</span>
          <LogOut className="h-4 w-4" />
        </Button>
      )}
      {user && isPending && (
        <>
          <Button variant="ghost" disabled onClick={logout} size="sm">
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            <span className="hidden sm:inline mr-2">Logout</span>
            <LogOut className="h-4 w-4" />
          </Button>
        </>
      )}
      <p>
        Here are your recent orders. More details and options will be listed
        eventually.
      </p>
      {isAdmin ? (
        <div className="grid gap-12">
          <AdminDashboard />
          <UserDashboard />
        </div>
      ) : (
        <UserDashboard />
      )}
    </div>
  );
};
export default Dashboard;
