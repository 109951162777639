import { Switch } from "@/components/ui/switch";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { projectFirestore } from "@/firebase/config";
import { useAuthContext } from "@/hooks/useAuthContext";
import { useCollection } from "@/hooks/useCollection";
import { UserData } from "@/hooks/useSignup";
import formatCentsToCurrency from "@/lib/formatCentsToCurrency";
import formatTextList from "@/lib/formatTextList";
import formatTimestampToShortDate from "@/lib/formatTimestampToShortDate";
import { Timestamp, doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

const UserDashboard = () => {
  const { user } = useAuthContext();
  const { documents } = useCollection("users");
  const [orders, setOrders] = useState<ProcessedOrder[] | []>([]);

  type RawOrder = {
    createdAt: Timestamp;
    itemsPurchased: string[];
    paid: boolean;
    orderTotal: number;
    displayName: string;
  };

  type ProcessedOrder = {
    id: number;
    date: string;
    status: "Paid" | "Unpaid";
    beans: string[];
    orderTotal: number;
    displayName: string;
    fulfilled?: boolean;
  };

  useEffect(() => {
    if (!documents) return;
    const fetchOrders = async () => {
      const userOrders: RawOrder[] = documents
        .map((item) => {
          const userName = item.displayName;
          return item.orders.map((order) => ({
            ...order,
            displayName: userName,
          }));
        })
        .flat();

      const processedOrders = await Promise.all(
        userOrders.map(async (order) => {
          return {
            id: order.createdAt.toMillis(),
            date: formatTimestampToShortDate(order.createdAt),
            status: order.paid ? "Paid" : "Unpaid",
            beans: await Promise.all(
              order.itemsPurchased.map(async (item) => {
                const orderDoc = await getDoc(
                  doc(projectFirestore, "coffees", item)
                );
                const beanName = await orderDoc.get("name");
                return beanName;
              })
            ),
            orderTotal: order.orderTotal,
            fulfilled: order.fulfilled || false,
            displayName: order.displayName,
          };
        })
      );

      setOrders(processedOrders);
    };
    fetchOrders();
  }, [user, documents]);

  return (
    <div className="overflow-hidden">
      {orders.length > 0 && (
        <Table>
          <TableCaption>A list of recent orders.</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">Date</TableHead>
              <TableHead className="w-[100px]">Client</TableHead>
              <TableHead className="w-[100px]">Status</TableHead>
              <TableHead>Order Items</TableHead>
              <TableHead className="text-right">Amount</TableHead>
              <TableHead className="text-right">Fulfilled</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {orders
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .map((order) => (
                <TableRow key={order.id}>
                  <TableCell>{order.date}</TableCell>
                  <TableCell className="w-[100px]">
                    {order.displayName}
                  </TableCell>
                  <TableCell className="w-[100px]">{order.status}</TableCell>
                  <TableCell>{formatTextList.format(order.beans)}</TableCell>
                  <TableCell className="text-right">
                    {formatCentsToCurrency(order.orderTotal)}
                  </TableCell>
                  <TableCell>
                    <Switch
                      className="data-[state=checked]:bg-emerald-500"
                      // onClick={() => handleFulfilledClick(order)}
                      checked={order.fulfilled}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};
export default UserDashboard;
