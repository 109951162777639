import { useCartContext } from "@/hooks/useCartContext";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { toast } from "@/components/ui/use-toast";
import { MinusCircle, PlusCircle, Trash2, View } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaymentScreen from "./PaymentScreen";
import { useAuthContext } from "@/hooks/useAuthContext";
import getCoffeeDetails from "@/lib/getCoffeeDetails";
import { httpsCallable } from "firebase/functions";
import { projectFunctions } from "@/firebase/config";
import formatTextList from "@/lib/formatTextList";

import CoffeeOrderEmail from "./CheckoutEmail";
import { render } from "@react-email/render";
import formatCentsToCurrency from "@/lib/formatCentsToCurrency";
import { greenCoffee } from "../AdminDashboard/AddCoffeeForm";

const CheckoutPage = () => {
  const { user } = useAuthContext();
  const [tempCost, setTempCost] = useState(0);
  const [showPayment, setShowPayment] = useState(false);
  const { cart, coffees, removeFromCart, addToCart, checkout } =
    useCartContext();
  const navigate = useNavigate();
  const notifyAdmin = httpsCallable(projectFunctions, "notifyAdmin");
  const sendCustomerEmail = httpsCallable(
    projectFunctions,
    "sendCustomerEmail"
  );

  const processedCoffees = Object.entries(
    cart.reduce((acc, curr) => {
      acc[curr] = acc[curr] ? acc[curr] + 1 : 1;
      return acc;
    }, {})
  ).sort((a, b) => a[0].localeCompare(b[0]));

  const totalCost = processedCoffees.reduce((acc, [id, qty]) => {
    const coffee = coffees.find((coffee) => coffee.id === id);
    const price = coffee?.customer_price_in_cents * qty;
    return acc + price;
  }, 0);

  const handleCheckoutClick = async () => {
    setTempCost(totalCost);
    try {
      await checkout(processedCoffees, totalCost);
      toast({
        title: "Order placed",
        description: "Thank you for your order!",
      });
      setShowPayment(true);
      const coffeeDetail = processedCoffees.map(([id, qty]) => {
        const detail = getCoffeeDetails({
          qty,
          coffeeID: id,
          coffees,
          keys: ["name", "origin", "customer_price_in_cents"],
        });
        return `${detail.qty} ${detail.origin} ${detail.name}${
          detail.qty > 1 ? "s" : ""
        }`;
      });
      await sendCustomerEmail({
        toEmail: user.email,
        emailHtml: render(
          <CoffeeOrderEmail
            userName={user.displayName}
            orderDetails={formatTextList.format(coffeeDetail)}
          />,
          {
            pretty: true,
          }
        ),
      });
      await notifyAdmin({
        title: `New coffee order from ${user.displayName}`,
        text: `${formatTextList.format(coffeeDetail)}`,
        actions: [
          {
            name: "Add Coffee to OF",
            shortcut: "Add Coffee To Omnifocus",
            input: `New coffee order from ${
              user.displayName
            }. ${formatTextList.format(coffeeDetail)}`,
          },
        ],
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Something went wrong",
        description: "Please try again",
      });
    }
  };

  useEffect(() => {
    if (cart.length === 0) {
      navigate(-1);
      toast({
        title: "Cart is empty",
        description: "Add some coffee to your cart to checkout",
      });
    }
  }, []);

  return (
    <div className="grid place-items-center gap-4">
      {showPayment && <PaymentScreen totalCost={tempCost} />}
      {!showPayment && (
        <div className="shadow-md rounded-md grid place-items-center gap-4 p-4 sm:p-8 relative overflow-hidden">
          <div className="h-1 absolute bg-secondary w-full top-0 left-0"></div>
          <p className="font-bold text-2xl sm:text-3xl">Your Cart</p>
          <p className="max-w-md text-center">
            Hey, {user.displayName}! Once you order, you will be directed to
            available payment methods. Your order will be saved to your account
            for future reference.
          </p>
          <div className="max-w-fit mx-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead className="text-center">Qty</TableHead>
                  <TableHead className="text-right">Total</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {processedCoffees &&
                  processedCoffees.map(([id, quantity]) => {
                    const coffee = coffees.find(
                      (coffee) => coffee.id === id
                    ) as greenCoffee;
                    return (
                      <>
                        {coffee && (
                          <TableRow key={id}>
                            <TableCell className="max-w-[135px] sm:max-w-[350px]">
                              {coffee.origin} {coffee.name}
                            </TableCell>
                            <TableCell className="text-center">
                              <div className="flex items-center gap-3">
                                <Button
                                  variant={"ghost"}
                                  size="minimized"
                                  className="h-4 hover:scale-105 focus-visible:scale-105 rounded-full"
                                  aria-label={"One less coffee"}
                                  onClick={() => removeFromCart(coffee.id)}
                                >
                                  <MinusCircle width="16" />
                                </Button>
                                <p className="text-sm font-medium">
                                  {quantity}
                                </p>
                                <Button
                                  variant={"ghost"}
                                  size="minimized"
                                  className="h-4 hover:scale-105 focus-visible:scale-105 rounded-full"
                                  aria-label={"One more coffee"}
                                  onClick={() => addToCart(coffee.id)}
                                >
                                  <PlusCircle width="16" />
                                </Button>
                              </div>
                            </TableCell>
                            <TableCell className="text-right font-medium">
                              {formatCentsToCurrency(
                                coffee.customer_price_in_cents * quantity
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })}
                <TableRow className="text-right font-bold" key="footer">
                  <TableCell colSpan={2}>Total</TableCell>
                  <TableCell>{formatCentsToCurrency(totalCost)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {cart.length > 0 && (
              <Button
                variant={"secondary"}
                size="lg"
                className="w-full"
                onClick={handleCheckoutClick}
              >
                Place Order for {formatCentsToCurrency(totalCost)}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default CheckoutPage;
