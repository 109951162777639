// page imports
import React from "react";
import Login from "@/pages/Login";
import { useAuthContext } from "./hooks/useAuthContext";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import Layout from "@/pages/layouts/Layout";
import Signup from "./pages/Signup";
import Home from "./pages/Home";
import Dashboard from "./pages/layouts/Dashboard";
import Error from "./pages/Error";
import CheckoutPage from "./pages/Checkout/CheckoutPage";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://6ff23c351b2292a8ee463fc96bb796aa@o4506125338017792.ingest.sentry.io/4506125340966912",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

function App() {
  const { user } = useAuthContext();

  return (
    <>
      <RouterProvider
        router={sentryCreateBrowserRouter([
          {
            path: "/",
            element: <Layout />,
            errorElement: <Error />,
            children: [
              {
                index: true,
                element: <Home />,
              },
              {
                path: "dashboard",
                element: user ? <Dashboard /> : <Navigate to="/login" />,
              },
              {
                path: "login",
                element: !user ? <Login /> : <Navigate to="/" />,
              },
              {
                path: "signup",
                element: !user ? <Signup /> : <Navigate to="/" />,
              },
              {
                path: "checkout",
                element: !user ? <Login /> : <CheckoutPage />,
              },
            ],
          },
        ])}
      />
    </>
  );
}

export default App;
