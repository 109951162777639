import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ToastAction } from "@/components/ui/toast";
import { toast } from "@/components/ui/use-toast";
import { useCartContext } from "@/hooks/useCartContext";
import { useCollection } from "@/hooks/useCollection";
import { useFirestore } from "@/hooks/useFirestore";
import { MinusCircle, PlusCircle, Trash2, View } from "lucide-react";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { greenCoffee } from "../AdminDashboard/AddCoffeeForm";
import { HeadRow } from "react-day-picker";
import { useAuthContext } from "@/hooks/useAuthContext";
import formatCentsToCurrency from "@/lib/formatCentsToCurrency";

export default function CartTable() {
  const { cart, coffees, removeFromCart, addToCart } = useCartContext();

  const processedCoffees = Object.entries(
    cart.reduce((acc, curr) => {
      acc[curr] = acc[curr] ? acc[curr] + 1 : 1;
      return acc;
    }, {})
  ).sort((a, b) => a[0].localeCompare(b[0]));

  const totalCost = processedCoffees.reduce((acc, [id, qty]) => {
    const coffee = coffees.find((coffee) => coffee.id === id);
    const price = coffee?.customer_price_in_cents * qty;
    return acc + price;
  }, 0);

  return (
    <>
      <p className="font-bold text-2xl">Cart Items</p>
      <Table className="max-w-fit">
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead className="text-center">Qty</TableHead>
            <TableHead className="text-right">Total</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {processedCoffees &&
            processedCoffees.map(([id, quantity]) => {
              const coffee = coffees.find((coffee) => coffee.id === id);
              return (
                <>
                  {coffee && (
                    <TableRow key={id}>
                      <TableCell className="max-w-[135px] sm:max-w-[350px]">
                        {coffee.origin} {coffee.name}
                      </TableCell>
                      <TableCell className="text-center">
                        <div className="flex items-center gap-3">
                          <Button
                            variant={"ghost"}
                            size="minimized"
                            className="h-4 hover:scale-105 focus-visible:scale-105 rounded-full"
                            aria-label={"One less coffee"}
                            onClick={() => removeFromCart(coffee.id)}
                          >
                            <MinusCircle width="16" />
                          </Button>
                          <p className="text-sm font-medium">{quantity}</p>
                          <Button
                            variant={"ghost"}
                            size="minimized"
                            className="h-4 hover:scale-105 focus-visible:scale-105 rounded-full"
                            aria-label={"One more coffee"}
                            onClick={() => addToCart(coffee.id)}
                          >
                            <PlusCircle width="16" />
                          </Button>
                        </div>
                      </TableCell>
                      <TableCell className="text-right font-medium">
                        {formatCentsToCurrency(
                          coffee.customer_price_in_cents * quantity
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            })}
          <TableRow className="text-right font-bold" key="footer">
            <TableCell colSpan={2}>Total</TableCell>
            <TableCell>{formatCentsToCurrency(totalCost)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}
