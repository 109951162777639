import { createContext, useReducer, useEffect, ReactNode } from "react";
import { projectAuth, projectFirestore } from "../firebase/config";
import { User, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

type AuthDispatch = {
  type: "LOGIN" | "LOGOUT" | "AUTH_IS_READY";
  payload?: User;
  isAdmin?: boolean;
};

type AuthState = {
  user: User | null;
  authIsReady: boolean;
  isAdmin: boolean;
};

type AuthContextType = AuthState & {
  dispatch: React.Dispatch<AuthDispatch>;
};

export const AuthContext = createContext<AuthContextType>({
  user: null,
  authIsReady: false,
  isAdmin: false,
  dispatch: () => null,
});

export const authReducer = (state: AuthState, action: AuthDispatch) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload, isAdmin: action.isAdmin };
    case "LOGOUT":
      return { ...state, user: null, isAdmin: false };
    case "AUTH_IS_READY":
      return {
        user: action.payload,
        authIsReady: true,
        isAdmin: action.isAdmin,
      };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
    isAdmin: false,
  });

  useEffect(() => {
    const unsub = onAuthStateChanged(projectAuth, async (user) => {
      let adminStatus = false;
      if (user) {
        const userRef = doc(projectFirestore, "users", user.uid);
        const userDoc = await getDoc(userRef);
        adminStatus = userDoc.data().role === "admin";
      }
      dispatch({ type: "AUTH_IS_READY", payload: user, isAdmin: adminStatus });
      unsub();
    });
  }, []);

  // console.log("AuthContext state:", state);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
