import { Button } from "@react-email/button";
import { Body } from "@react-email/body";
import { Container } from "@react-email/container";
import { Head } from "@react-email/head";
import { Hr } from "@react-email/hr";
import { Html } from "@react-email/html";
import { Img } from "@react-email/img";
import { Preview } from "@react-email/preview";
import { Section } from "@react-email/section";
import { Text } from "@react-email/text";

export const CoffeeOrderEmail = ({
  userName,
  orderDetails,
}: {
  userName: string;
  orderDetails: string;
}) => (
  <Html>
    <Head />
    <Preview>Get ready for amazing coffee!</Preview>
    <Body style={main}>
      <Container style={container}>
        <Section style={box}>
          <Img
            src={"https://penningtonroasting.com/images/email-icon.jpg"}
            width="250"
            height="95"
            alt="Pennington Coffee"
          />
          <Text style={paragraph}>
            Thank you for your order, {userName}! I’ll get started right away.
            If you have any special instructions, please reply to this email.
          </Text>
          <Text style={paragraph}>Here's what you ordered: {orderDetails}</Text>
          <Text style={paragraph}>
            You can view your orders and a variety of other information about
            your account right from your dashboard.
          </Text>
          <Button
            pX={10}
            pY={10}
            style={button}
            href="https://penningtonroasting.com/dashboard"
          >
            View your Dashboard
          </Button>
          <Text style={paragraph}>
            Thanks again for your order. Coffee 🤝 Friendship
          </Text>
          <Text style={paragraph}>— Chris</Text>
          <Hr style={hr} />
          <Text style={footer}>
            3160 E 4800 N, Eden, UT 84310, United States
          </Text>
        </Section>
      </Container>
    </Body>
  </Html>
);

export default CoffeeOrderEmail;

const main = {
  backgroundColor: "#f6f9fc",
  fontFamily:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif',
};

const container = {
  backgroundColor: "#ffffff",
  margin: "0 auto",
  padding: "20px 0 48px",
  marginBottom: "64px",
};

const box = {
  padding: "0 48px",
};

const hr = {
  borderColor: "#e6ebf1",
  margin: "20px 0",
};

const paragraph = {
  color: "#525f7f",

  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "left" as const,
};

const button = {
  backgroundColor: "#FD7231",
  borderRadius: "5px",
  color: "#fff",
  fontSize: "16px",
  fontWeight: "bold",
  textDecoration: "none",
  textAlign: "center" as const,
  display: "block",
  width: "100%",
};

const footer = {
  color: "#8898aa",
  fontSize: "12px",
  lineHeight: "16px",
};
