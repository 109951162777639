import { greenCoffee } from "@/pages/AdminDashboard/AddCoffeeForm";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Badge } from "./ui/badge";
import { Button } from "./ui/button";
import { ExternalLink, Plus } from "lucide-react";
import { useCartContext } from "@/hooks/useCartContext";
import { toast } from "./ui/use-toast";
import { Link } from "react-router-dom";
import formatCentsToCurrency from "@/lib/formatCentsToCurrency";

const CoffeeCard = ({ coffee }: { coffee: greenCoffee; index: number }) => {
  const { addToCart, cart } = useCartContext();

  const handleAddToCart = () => {
    addToCart(coffee.id);
    toast({
      title: "Added to cart",
      description: `12oz bag of ${coffee.name}`,
      action: (
        <Button variant={"secondary"} asChild>
          <Link to="/checkout">Checkout</Link>
        </Button>
      ),
    });
  };

  const cart_inventory = cart.filter((id) => id === coffee.id).length ?? 0;
  const temp_inventory = coffee.current_inventory - cart_inventory;

  return (
    <Card key={coffee.id} className="relative overflow-hidden">
      {coffee.coming_soon && (
        <p className="absolute top-8 -left-16 shadow-xl -rotate-45 bg-secondary text-popover py-2 px-14 font-bold text-md tracking-wide">
          Coming Soon
        </p>
      )}
      <img
        src={coffee.image_src}
        className="rounded-t-md h-40 object-cover w-full"
        alt=""
      />
      <CardHeader>
        <Badge variant={"outline"} className="max-w-fit mb-4 border-secondary">
          {temp_inventory} remaining
        </Badge>
        <CardTitle>{coffee.name}</CardTitle>
        <CardDescription>{coffee.origin}</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4">
        <p>{coffee.tasting_notes} </p>
        <Button
          variant={"outline"}
          size={"sm"}
          className="sm:max-w-fit"
          asChild
        >
          <a
            href={coffee.link_to_bean}
            className="flex items-center gap-2 text-sm"
          >
            <span>Learn More</span>
            <ExternalLink width="16" />
          </a>
        </Button>
      </CardContent>
      <CardFooter className="border-t border-dotted pt-6 flex items-center justify-between">
        <p className="font-bold text-secondary text-xl sm:text-3xl">
          {formatCentsToCurrency(coffee.customer_price_in_cents)}
        </p>
        <Button
          aria-label={"One more coffee"}
          onClick={handleAddToCart}
          disabled={coffee.coming_soon}
          title={coffee.coming_soon ? "Coming soon" : undefined}
        >
          <Plus width="16" />
          <span>Add to Cart</span>
        </Button>
      </CardFooter>
    </Card>
  );
};
export default CoffeeCard;
