import { Link, useRouteError } from "react-router-dom";
import Nav from "./layouts/Nav";
import { Button } from "@/components/ui/button";

const Error = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="grid grid-rows-layout gap-6 sm:gap-10 min-h-screen text-lg sm:text-xl">
      <Nav />
      <main className="container">
        <div className="grid place-content-center min-h-full text-center gap-4">
          <h1 className="text-2xl sm:text-4xl font-bold text-secondary">
            Oops!
          </h1>
          <p className="text-xl">Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{(error as Error).statusText || (error as Error).message}</i>
          </p>
          <div className="flex flex-wrap justify-center gap-6 items-center">
            <Button variant={"default"}>
              <Link to="/">Return Home</Link>
            </Button>
          </div>
        </div>
      </main>
      <footer className="text-center p-2 border-t border-secondary text-sm">
        Pennington Roasting
      </footer>
    </div>
  );
};
export default Error;
